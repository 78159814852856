import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import { SideBox } from "../components/styledComponents"
import ContactForm from "../components/contactForm"
import ArrowMore from "../components/arrowMore"
import {
  BigMobileFont,
  MediumMobileFont,
  SmallMobileFont,
} from "../components/typography"
import BaseImg from "../components/baseImg"
import ServicesSection from "../components/servicesSection"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import SidesBorderedBox from "../components/sidesBorderedBox"
import ListSection from "../components/listSection"
import { BaseButton } from "../components/styledComponents"
import PortfolioSlider from "../components/sliders/portfolioSlider"
import CaseStudySection from "../components/caseStudySection"
import ArticlesGrid from "../components/articlesGrid"

const TwoColumns = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.desktopMargin};
  @media (max-width: ${(props) => props.breakpoint}) {
    margin: 0;
    flex-direction: column;
  }

  .gatsby-image-wrapper {
    height: auto !important;
  }
`

const ContactInfo = styled.div`
  padding: 32px;
  p {
    margin-bottom: 0.5rem;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const ColumnImage = styled(BaseImg)`
  width: 47%;
  margin-bottom: 0;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const EdgeImg = styled(BaseImg)`
  position: relative;
  z-index: -1;
  width: 90vw;
  margin: -14% -23vw -5% 0;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`
const ArrowMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 -40px 40px 0;
  @media (max-width: 768px) {
    margin: 0;
  }
`
const StyledSideBox = styled(SideBox)`
  margin-left: 0;
  padding-left: 0;
  width: 100%;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    padding: 32px 16px;
  }
`
const SideBoxWrapper = styled.div`
  width: 66%;
  min-width: 450px;
  @media (max-width: 768px) {
    width: 100%;
    min-width: auto;
  }
`
const HeroText = styled.p`
  font-size: 1.125rem;
  line-height: 1.6;
  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`
const Landing = ({ pageContext }) => {
  const { metaboxes, yoast_meta, blogPosts } = pageContext
  const {
    buttonText,
    buttonUrl,
    clientsSectionTitle,
    contactHeader,
    contactMail,
    contactMailPrefix,
    contactMobile,
    contactMobilePrefix,
    contactName,
    contactRole,
    gainTitle,
    gainContent,
    title,
    text,
    servicesSectionTitle,
    portfolioSliderTitle,
    listSectionTitle,
    services,
    list,
    heroImage,
    gainImage,
    contactImage,
    contactFormTitle,
    caseStudyImage,
    caseStudyTitle,
    caseStudyText,
    partners,
    caseStudyButtonText,
    caseStudyButtonUrl,
    blogSectionTitle,
  } = metaboxes

  const isCaseStudyDataCorrect =
    !!caseStudyImage &&
    !!caseStudyTitle &&
    !!caseStudyText &&
    !!partners &&
    !!caseStudyButtonText &&
    !!caseStudyButtonUrl

  return (
    <Layout>
      <SEO
        meta={yoast_meta}
        title={pageContext.title}
        path={pageContext.path}
      />
      <Container>
        <FlexContainer>
          <SideBoxWrapper>
            <StyledSideBox>
              <h1 style={{ marginBottom: "0.75rem" }}>
                <BigMobileFont desktopFontSize="2.5rem">{title}</BigMobileFont>
              </h1>
              <HeroText dangerouslySetInnerHTML={{ __html: text }}></HeroText>
              {buttonText && (
                <BaseButton to={buttonUrl}>{buttonText}</BaseButton>
              )}
            </StyledSideBox>
            <ArrowMoreWrapper>
              <ArrowMore />
            </ArrowMoreWrapper>
          </SideBoxWrapper>
          <EdgeImg localFile={heroImage.localFile} alt={heroImage.alt_text} />
        </FlexContainer>
      </Container>
      <Container>
        <ServicesSection title={servicesSectionTitle} services={services} />

        <FlexContainer style={{ margin: "130px 0" }}>
          <ColumnImage localFile={gainImage.localFile} alt="" />
          <SidesBorderedBox side="right">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont desktopFontSize="1.75rem">
                {gainTitle}
              </MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: gainContent }}
            ></div>
          </SidesBorderedBox>
        </FlexContainer>

        <CenterBorderedHeader>
          <BigMobileFont desktopFontSize="2.5rem">
            {portfolioSliderTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <PortfolioSlider />
      </Container>
      {isCaseStudyDataCorrect ? (
        <CaseStudySection
          image={caseStudyImage}
          title={caseStudyTitle}
          text={caseStudyText}
          partners={partners}
          buttonText={caseStudyButtonText}
          buttonUrl={caseStudyButtonUrl}
        />
      ) : null}

      <Container>
        <TwoColumns breakpoint="900px" style={{ marginTop: "96px" }}>
          <TwoColumns breakpoint="1195px" desktopMargin="0 32px 0 0">
            <BaseImg
              style={{ width: "280px", height: "280px", marginBottom: "0" }}
              localFile={contactImage.localFile}
              alt=""
            />
            <ContactInfo>
              <h2 style={{ marginBottom: "0.75rem" }}>
                <SmallMobileFont style={{ fontWeight: "700" }}>
                  {contactHeader}
                </SmallMobileFont>
              </h2>
              <p style={{ fontWeight: "700" }}>{contactName}</p>
              <p>{contactRole}</p>
              <p>
                {`${contactMailPrefix} `}
                <a style={{ fontWeight: "700" }} href={`mailto:${contactMail}`}>
                  {contactMail}
                </a>
              </p>
              <p>
                {`${contactMobilePrefix} `}
                <a style={{ fontWeight: "700" }} href={`tel:${contactMobile}`}>
                  {contactMobile}
                </a>
              </p>
            </ContactInfo>
          </TwoColumns>
          <ContactForm title={contactFormTitle} />
        </TwoColumns>
      </Container>

      <ListSection title={listSectionTitle} items={list} position="center" />

      <div style={{ margin: "0 0 130px 0" }}>
        <Container>
          <CenterBorderedHeader>
            <BigMobileFont desktopFontSize="2.5rem">
              {blogSectionTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <ArticlesGrid posts={blogPosts.data.allWordpressPost.nodes} />
        </Container>
      </div>
    </Layout>
  )
}

export default Landing
